import React from 'react';
import './base.css';

function Resume() {
  return (
    <div className="Resume">
      <h2>Resume</h2>
    </div>
  );
}

export default Resume;
